<template>
  <div class="information-component container g-0 g-xl-2 mt-0 pt-0 mt-xl-5 pt-xl-5">
    <div class="row g-0">
      <div class="col-12 col-xl-6">
        <div class="retort-flange-image"></div>
      </div>
      <div class="col-12 col-xl-6 px-2 px-xl-0">
        <div class="row">
          <div class="col-12 mb-3">
            <h2 class="text-suro-blue font-weight-bold">{{ $t('welcomeToSurotech') }}</h2>
          </div>
          <div class="col-12 mt-2" v-for="i in [0, 1, 2]" :key="i">
            <p class="h5">{{ $t(`welcomeChapters.chapter${i}`) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import 'bootstrap';
.retort-flange-image {
  width: 300px;
  height: 200px;
  background-image: url('../assets/retortFlange.png');
  background-size: cover;
}

@include media-breakpoint-up(md) {
  .retort-flange-image {
    width: 650px;
    height: 432px;
  }
}
</style>
