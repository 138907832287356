export default {
  "welcomeToSurotech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WELCOME TO SUROTECH"])},
  "welcomeChapters": {
    "chapter0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The company Surotech Martin Schmid has been founded 1st October 2022 by Martin Schmid, owner and General Manager of Surmetal AG."])},
    "chapter1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After reaching his pension age, Mr Schmid will support his customers worldwide (mainly Far East) in construction, operation and service. Surotech will also help with technical know-how and the supply of spare- and wear parts."])},
    "chapter2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Further Surotech will continue the support and sale of wear parts to CVD equipment, specifically the sale of retorts."])}
  },
  "contact": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surotech Martin Schmid"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nüberichstrasse 11"])},
    "zipTown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CH-5024, Kuettigen"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switzerland"])},
    "telephoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tel"])},
    "mobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
  }
}