<template>
  <div class="app w-100 h-100 d-flex flex-column">
    <NavbarComponent></NavbarComponent>
    <main class="w-100">
      <InformationComponent></InformationComponent>
    </main>
    <footer class="w-100">
      <FooterComponent></FooterComponent>
    </footer>
  </div>
</template>
<style lang="scss">
footer {
  margin-top: auto;
}
</style>
<script>
import NavbarComponent from './components/NavbarComponent.vue';
import InformationComponent from './components/InformationComponent.vue';
import FooterComponent from './components/FooterComponent.vue';

export default {
  components: {
    NavbarComponent,
    InformationComponent,
    FooterComponent,
  },
};
</script>
