export default {
  "welcomeToSurotech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WILKOMMEN BEI SUROTECH"])},
  "welcomeChapters": {
    "chapter0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Firma Surotech wurde am 1. Oktober 2022 durch Martin Schmid, Inhaber der Firma Surmetal AG gegründet."])},
    "chapter1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hr. Schmid wird auch nach seiner Pensionierung weiterhin seine Kunden auf der ganzen Welt, (spezifisch Fernost) betreuen und bei Bau, Betrieb und Service sowie der Beschaffung von Ersatz- und Verschleissteilen zur Verfügung stehen."])},
    "chapter2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zudem wird Martin Schmid die Beratung, Beschaffung und Verkauf von Bauteilen- und Verschleissteilen zu CVD-Anlagen weiterhin anbieten."])}
  },
  "contact": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surotech Martin Schmid"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nüberichstrasse 11"])},
    "zipTown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CH-5024, Küttigen"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schweiz"])},
    "telephoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tel"])},
    "mobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
  }
}