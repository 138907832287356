<template>
  <nav class="navbar-component w-100 border-bottom-ad">
    <div class="container h-100">
      <div class="row h-100 align-items-center">
        <div class="col-8 d-flex justify-content-start">
          <div class="logo"></div>
          <div class="logo-no-turtle"></div>
        </div>
        <div class="col-4 d-flex justify-content-center">
          <div
            title="Deutsch"
            class="language-flag german-flag hoverable clickable mx-2"
            @click="setLocale('de')"
          ></div>
          <div
            title="English"
            class="language-flag american-flag hoverable clickable mx-2"
            @click="setLocale('en')"
          ></div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  methods: {
    setLocale(lang) {
      this.$i18n.locale = lang;
    },
  },
};
</script>
<style lang="scss">
@import 'bootstrap';
.navbar-component {
  height: 80px;
}
.logo {
  max-width: 450px;
  width: 100%;
  height: 70px;
  background-image: url('../assets/logoNoTurtle.png');
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.language-flag {
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
}
.german-flag {
  width: 70px;
  height: 40px;
  background-image: url('../assets/germanFlag.svg');
}
.american-flag {
  width: 76px;
  height: 40px;
  background-image: url('../assets/americanFlag.svg');
}

@include media-breakpoint-up(sm) {
  .logo {
    background-image: url('../assets/logo.png');
  }
}
</style>
