<template>
  <nav class="footer-component w-100 border-top-ad mt-3">
    <div class="container h-100">
      <div class="row h-100 align-items-center justify-content-center">
        <div class="col-auto align-items-center border-right-ad">
          <p class="font-weight-bold mb-0">
            {{ $t('contact.header') }}
          </p>
        </div>
        <div class="col-auto align-items-center border-right-ad">
          <p class="mb-0">{{ $t('contact.address') }}</p>
        </div>
        <div class="col-auto align-items-center border-right-ad">
          <p class="mb-0">{{ $t('contact.zipTown') }}</p>
        </div>
        <div class="col-auto align-items-center border-right-ad">
          <p class="mb-0">{{ $t('contact.country') }}</p>
        </div>
        <div class="col-auto align-items-center border-right-ad">
          <p class="mb-0">{{ $t('contact.telephoneNumber') }}: <a href="tel:+41622969373">+41 62 296 93 73</a></p>
        </div>
        <div class="col-auto align-items-center border-right-ad">
          <p class="mb-0">{{ $t('contact.mobileNumber') }}: <a href="tel:+41794814486">+41 79 481 44 86</a></p>
        </div>
        <div class="col-auto align-items-center">
          <p class="mb-0">{{ $t('contact.email') }}: <a href="mailto:info@surotech.ch">info@surotech.ch</a></p>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  methods: {
    setLocale(lang) {
      this.$i18n.locale = lang;
    },
  },
};
</script>
<style lang="scss">
@import 'bootstrap';
.footer-component {
  height: 50px;
}
</style>
